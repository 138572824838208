<template>
  <div>
    <order-table :item="item" :isAdmin="true" />
    <vs-card>
      <div class="hidden lg:block w-1/2">
        <p><strong>All Activity</strong></p>
        <div class="mt-2 bordered">
          <p class="color">
            <span class="success"> OK </span> Order successfully created.
          </p>
        </div>
        <div
          class="mt-2 bordered"
          v-if="orderData.length && (orderData[0].shippingDetails.superAdminEmail && orderData[0].shippingDetails.superAdminId)">
          <p class="color">
            <span class="success"> OK </span> Bought by Super-Admin.
          </p>
        </div>
        <div class="mt-2 bordered">
          <p v-if="orderData.length && orderData[0].didPaymentPushedToStripe" class="color">
            <span class="success"> OK </span>Payment intent successfully
            submitted.
          </p>
          <p v-else :style="{ color: 'indianred' }">
            <span class="fail"> FAIL </span> Payment intent failed to submit to
            Stripe.
          </p>
        </div>
        <div class="mt-2 bordered">
          <p v-if="
            !orderData[0].didHubspotFail &&
            (orderData[0].paymentType !== 'ClinicBankAccount' ||
              orderData[0].paymentType !== 'SavedClinicBankAccount')
          " class="color">
            <span class="success"> OK </span>Order submitted to Hubspot.
          </p>
          <p v-else-if="
            !orderData[0].didHubspotFail &&
            orderData[0].status === 'Paid' &&
            (orderData[0].paymentType === 'ClinicBankAccount' ||
              orderData[0].paymentType === 'SavedClinicBankAccount')
          " class="color">
            <span class="success"> OK </span>Order submitted to Hubspot.
          </p>
          <p v-if="orderData[0].didHubspotFail" :style="{ color: 'indianred' }">
            <span class="fail"> FAIL </span> Hubspot submission failed.
            <vs-button size="small">RETRY</vs-button>
          </p>
        </div>
        <div class="mt-2 bordered">
          <p v-if="!orderData[0].didXeroFail" class="color">
            <span class="success"> OK </span>Order submitted to Xero.
            <vs-button size="small" @click="resubmitToXero()">RETRY</vs-button>
          </p>
          <p v-if="orderData[0].didXeroFail" class="color">
            <span class="fail"> FAIL </span>Xero submission failed.
            <vs-button size="small" @click="resubmitToXero()">RETRY</vs-button>
          </p>
          <p :style="{ color: 'indianred' }"></p>
        </div>
        <div class="mt-2 bordered">
          <p v-if="!orderData[0].isSyncedWithSage" class="color">
            <span class="fail"> FAIL </span>Sage Order submission failed.
            <vs-button size="small" @click="resubmitToSage()">RETRY</vs-button>
          </p>
            <p v-else class="color">
                <span class="success"> OK </span>Order submitted to Sage.
            </p>
          <p :style="{ color: 'indianred' }"></p>
        </div>
      </div>
    </vs-card>
    <email-details :orderId="orderId"></email-details>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";
import OrderTable from './OrderTable'
import EmailDetails from './EmailDetails.vue'

export default {
  name: "OrderDetails",
  components: {
    OrderTable,
    EmailDetails
  },
  props: {
    orderId: {
      type: String,
    },
  },
  data() {
    return {
      id: this.orderId,
      orderData: [],
      item: {},
      currency: process.env.VUE_APP_CURRENCY,
      show: false
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any();
    },
  },
  mounted() { },
  methods: {
    ...mapActions("storeOrder", [
      "getOrderDetails",
      "sendInvoiceToClinicFromAdmin",
      "updateOrderTracking",
      "resubmitXero",
      "resubmitSage"
    ]),
    moment(date) {
      return moment(date);
    },
    async resubmitToXero() {
      this.$vs.loading();
      let self = this;
      await this.resubmitXero(this.orderData[0]._id)
        .then((res) => {
          this.orderData[0].didXeroFail = false;
          this.$vs.loading.close();
          this.$vs.notify({
            title: res.data.title,
            text: res.data.message,
            color: "success",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
        })
        .catch((error) => {
          this.$vs.loading.close();
          self.$vs.notify({
            title: error.data.title,
            text: error.data.message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
          self.$vs.loading.close();
        });
    },
    async resubmitToSage() {
      this.$vs.loading();
      await this.resubmitSage(this.orderData[0]._id)
        .then((res) => {
        //   this.orderData[0].isSyncedWithSage = true;
          this.$vs.notify({
            title: res.data.title,
            text: res.data.message,
            color: "success",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
        })
        .catch((error) => {
          this.$vs.notify({
            title: error.data.title,
            text: error.data.message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
        }).finally(() =>  this.$vs.loading.close());
    },
    async saveTrackingNumber(orderNumber, body, index) {
      this.$vs.loading();
      let self = this;
      await this.updateOrderTracking({ orderNumber, body })
        .then((res) => {
          this.orderData[index].isTrackingEdit = true;

          this.orderData[index].trackingInfos.map((info) => {
            if (info._id.toString() === body.deliverySupplierCourierId)
              this.orderData[index].courierName = info.courierName;
          });

          // this.orderData[index].
          this.$vs.notify({
            title: res.data.title,
            text: res.data.message,
            color: "success",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
          this.$vs.loading.close();
        })
        .catch((error) => {
          self.$vs.notify({
            title: error.data.title,
            text: error.data.message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
          self.$vs.loading.close();
        });
    },
    async fetchOrderDetails(id) {
      this.$vs.loading();
      let self = this;
      await this.getOrderDetails(id).then((res) => {
        res.data.data.formattedResponse.orderList.map(function (value) {
          value.deliverySupplierCourierId = value.deliverySupplierCourierId
            ? value.deliverySupplierCourierId
            : "";
          value.trackingNumber = value.trackingNumber
            ? value.trackingNumber
            : "";
          value.isTrackingEdit = value.trackingNumber ? true : false;
        });
        self.orderData = res.data.data.formattedResponse.orderList;
        this.item = res.data.data.result[0];
        this.$vs.loading.close();
      });
    },
    sendInvoiceToClinic() {
      var self = this;
      self.$vs.loading();
      this.sendInvoiceToClinicFromAdmin({ id: this.id })
        .then((response) => {
          self.$vs.notify({
            title: response.data.title,
            text: response.data.message,
            color: "success",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
          self.$vs.loading.close();
        })
        .catch((error) => {
          self.$vs.notify({
            title: error.data.title,
            text: error.data.message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
          self.$vs.loading.close();
        });
    },
  },
  async created() {
    this.id = this.orderId;
    await this.fetchOrderDetails(this.id);
  },
};
</script>

<style scoped></style>
<style>
.color {
  color: #3dc9b3;
}

.bordered {
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 5px;
}

.success {
  color: white;
  background-color: lightgreen;
  padding-left: 3px;
  padding-right: 3px;
  margin-right: 5px;
  border-radius: 5px;
}

.fail {
  color: white;
  background-color: indianred;
  padding-left: 3px;
  padding-right: 3px;
  margin-right: 5px;
  border-radius: 5px;
}
</style>
